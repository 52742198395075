/// <reference path="./modules/file.d.tsx" />
/// <reference path="./modules/const.d.tsx" />
import "./assets/style/index.scss";
import React from "react";
import { hydrate } from "react-dom";
import { Router } from "react-router-dom";
import history from "./history";
import App from "./components/App";
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    document.body.classList.add("is-mobile");
hydrate(React.createElement(Router, { history: history },
    React.createElement(App, null)), document.getElementById("app"));

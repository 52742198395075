import * as Loadable from "react-loadable";
import PageLoading from "./components/PageLoading";
const Main = Loadable({
    loading: PageLoading,
    loader: () => import("./components/Main"),
    modules: ["./components/Main"]
});
const NotFound = Loadable({
    loading: PageLoading,
    loader: () => import("./components/NotFound"),
    modules: ["./components/NotFound"]
});
export const root = [
    {
        path: URL + "/",
        exact: true,
        component: Main
    },
    {
        path: URL + "*",
        component: NotFound
    }
];
